/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "lazysizes"
//require("prismjs/themes/prism-coy.css")
//require("prismjs/themes/prism-dark.css")
//require("prismjs/themes/prism-funky.css")
//require("prismjs/themes/prism-okaidia.css")
//require("prismjs/themes/prism-solarizedlight.css")
//require("prismjs/themes/prism-tomorrow.css")
//require("prismjs/themes/prism-twilight.css")
//require("prismjs/themes/prism.css")
import './static/scroll.css'
//import 'firebase/database'


require("prismjs/plugins/line-numbers/prism-line-numbers.css")
require("katex/dist/katex.min.css") //used to render LateX math markdown formulas 
